

























import { Vue, Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Logo, Textfield, Spinner } from '@components/UI';
import { LoginErrors } from '@shared/config/errors';

@Component({
    components: { Logo, Textfield, Spinner }
})
export default class SignInView extends Vue {

    private username: string = '';
    private password: string = '';
    private tfaPin: number | null = null;

    @Action
    private login: (any) => Promise<any>;

    private loading: boolean = false;
    private error: string = '';
    private errorObj: any;

    private showTfa: boolean = false;
    
    private async onSubmit(): Promise<any> {
        if (this.canSubmit) {
            try {
                var pin = (this.tfaPin) ? this.tfaPin : -1;

                this.loading = true;
                const response = await this.login({ username: this.username, password: this.password, pin: pin });

                this.loading = false;
                if(response.tfaNeeded) {
                    this.showTfa = true;
                    return;
                }
                
                this.error = '';
                this.$router.push('/');
            } catch (error) {

                this.loading = false;
                this.error = error.errorCode;
                this.errorObj = error;
            }
        }
    }

    private get errorMessage(): string | null {
        if (this.error) {
            if(this.error == "E_USER_BANNED")
                return LoginErrors[this.error] + ` <br/>Ban Reason: <strong>${this.errorObj.banReason}</strong>`;

            if (LoginErrors[this.error])
                return LoginErrors[this.error];
            
            return 'An Unknown Error Ocurred';
        }
        
        return null;
    }

    private get canSubmit(): boolean {
        return this.username.length > 0 && this.password.length > 0;
    }

}

